<template>
  <span class="uppercase text-sm text-white hidden lg:block w-full" v-html="currentNameDays">
  </span>
</template>
<script setup lang="ts">
const { t } = useI18n()

const { data: holidays } = useQuery(holidaysQuery)

const nameDays = computed(() => {
  return holidays.value?.data?.filter((h) => h.isNameDay) ?? []
})

const currentNameDays = computed(() => {
  if (nameDays.value.length > 1) {
    return t('Dnes má svátek {firstName} a {secondName}', {
      firstName: nameDays.value?.[0]?.name,
      secondName: nameDays.value?.[1]?.name,
    })
  }
  return t('Dnes má svátek {name}', { name: nameDays.value?.[0]?.name })
})
</script>
