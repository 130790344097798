export const holidaysQuery = queryOptions({
  queryKey: ['holidays'],
  queryFn: async (): Promise<{ data: { name: string; isNameDay: boolean }[] }> => {
    const now = useState('today', () => new Date())

    const res = await useFetch<{ data: { name: string; isNameDay: boolean }[] }>('/api/nameDays', {
      query: {
        day: now.value.getDate(),
        month: now.value.getMonth() + 1,
      },
    })
    return res?.data.value as { data: { name: string; isNameDay: boolean }[] }
  },
})
